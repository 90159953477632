import {
  Collapse,
  Flex,
  IconButton,
  Show,
  Text,
  chakra,
  useBoolean,
  useBreakpointValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { ListSitesResult } from "@yotta-vision/functions/rest/sites";
import { LifetimeResult } from "@yotta-vision/functions/rest/timestream/lifetime-site-metrics";
import { useMemo } from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useApi } from "~lib/api";
import { sumMeasure } from "~lib/metrics";
import SitesCard from "./sites-card";
import "./snap-scroll.css";
import StatCard from "./stat-card";

const GlobalStats = () => {
  const [isMaxHeight] = useMediaQuery("(max-height: 700px)");
  const isMinWidth = useBreakpointValue({
    base: true,
    md: false,
  });
  const [show, setShow] = useBoolean(false);

  const { data: sitesData } = useApi<ListSitesResult>(`/sites`);
  const { data: metricsData } = useApi<LifetimeResult>(
    `/timeseries/lifetime-site-metrics/solarleaf`,
  );

  const measureSums = useMemo(() => {
    if (!metricsData) {
      return {
        pv: 0,
        out: 0,
      };
    }

    return {
      pv: +sumMeasure(metricsData, "pv"),
      out: -sumMeasure(metricsData, "out"),
    };
  }, [metricsData]);

  return (
    <Flex direction="column" pointerEvents="auto">
      <Collapse in={!isMinWidth && !isMaxHeight ? true : show}>
        <Flex
          id="global-stats"
          direction="column"
          gap="4"
          maxH="calc(100vh - 16rem)"
          overflow="auto"
        >
          <SitesCard sites={sitesData ?? []} />

          <StatCard data-testid="solar_generation" title="Energy generated">
            <Text fontSize="3xl" fontWeight="600" color="statCard.amount">
              {(measureSums.pv / 1000).toFixed(2)}
              <chakra.span fontSize="xl">{" MWh"}</chakra.span>
            </Text>
          </StatCard>

          <StatCard data-testid="grid_support" title="Energy consumed">
            <Text fontSize="3xl" fontWeight="600" color="statCard.amount">
              {(measureSums.out / 1000).toFixed(2)}
              <chakra.span fontSize="xl">{" MWh"}</chakra.span>
            </Text>
          </StatCard>

          <StatCard data-testid="co_savings" title="CO₂ reduction">
            <Text fontSize="3xl" fontWeight="600" color="statCard.amount">
              {(measureSums.out * 7.09e-4).toFixed(2)}
              <chakra.span fontSize="xl">{" tons"}</chakra.span>
            </Text>
          </StatCard>
        </Flex>
      </Collapse>
      <Show breakpoint="(max-height: 700px)">
        <IconButton
          aria-label="toggle dropdown"
          as={show ? BsChevronDown : BsChevronUp}
          size="xs"
          colorScheme="whiteAlpha"
          textColor="yottaBlackNeutral"
          borderRadius="full"
          marginTop="5"
          onClick={() => setShow.toggle()}
        />
      </Show>
    </Flex>
  );
};

export default GlobalStats;
