import {
  Avatar,
  Button,
  Flex,
  Menu,
  MenuButton,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { ListSitesResult } from "@yotta-vision/functions/rest/sites";
import CustomSearchSelect from "components/custom-search-select";
import ProfileDropdown from "components/login-profile/profile-dropdown";
import YottaVisionLogo from "components/navbar/yotta-vision-logo";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { SessionValue } from "sst/node/auth/session";
import { useSession } from "~lib/future/contexts/session";
import LoginModal from "./login-modal/login-modal";

export default function PagesNav({
  showSearchBar,
  data,
  onSearch,
}: {
  showSearchBar: boolean;
  data?: ListSitesResult;
  onSearch?: (value: string) => void;
}) {
  const router = useRouter();
  const { session } = useSession();
  const [isLargerThan700] = useMediaQuery("(min-width: 700px)");

  const siteNames =
    data?.map((item) => ({ label: item.name, siteID: item.siteID })) || [];

  return (
    <Flex
      as="nav"
      justifyContent="space-between"
      alignItems="center"
      alignSelf="stretch"
      bg="subtle-bg"
      borderRadius="md"
      padding="5"
      pointerEvents="auto"
      mb={8}
    >
      <YottaVisionLogo width="auto" height="10" role="img" />
      <Flex alignItems="center" gap={5}>
        {isLargerThan700 &&
          showSearchBar &&
          session.type === "user" &&
          !!data && (
            <CustomSearchSelect
              data={siteNames}
              router={router}
              onSearch={onSearch}
            />
          )}
        <AuthButton session={session} />
      </Flex>
    </Flex>
  );
}

function AuthButton({ session }: { session: SessionValue }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const setLanguage = () => {
    const browserLanguage = window.navigator.language;
    localStorage.setItem("language", browserLanguage);
  };

  useEffect(() => {
    if (session && session.type === "user") {
      setLanguage();
    }
  }, [session]);

  if (session && session.type === "user") {
    return (
      <UserProfileDropdown
        email={session.properties.email}
        name={session.properties.name}
        avatarUrl={session.properties.avatarUrl}
      />
    );
  }

  return (
    <>
      <Button colorScheme="primary" aria-label="log in" onClick={onOpen}>
        Log In
      </Button>
      <LoginModal modalOpen={isOpen} modalClose={onClose} />
    </>
  );
}

function UserProfileDropdown({
  email,
  name,
  avatarUrl,
}: {
  email: string;
  name?: string;
  avatarUrl?: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton aria-label="dropdown" onClick={onOpen}>
        <Avatar
          size="sm"
          border="1px solid"
          borderColor="text"
          color="text"
          name={name ?? email}
          src={avatarUrl}
          bg="subtle-bg"
          onClick={onOpen}
          cursor="pointer"
        />
      </MenuButton>
      <ProfileDropdown />
    </Menu>
  );
}
