import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  th: {
    textTransform: "none",
  },
  thead: {
    borderBottom: "1px solid",
    borderColor: "labelBg",
  },
});

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    variant: "unstyled",
  },
});
