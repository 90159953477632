import axios from "axios";
import { getCookie } from "cookies-next";
import useSWR from "swr";

export const api = axios.create({ baseURL: process.env.NEXT_PUBLIC_API_URL });

api.interceptors.request.use((config) => {
  const token = getCookie("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

interface FetcherOptions {
  url: string;
  params?: unknown;
}

const fetcher = <T = unknown>({ url, params }: FetcherOptions) =>
  api.get<T>(url, { params }).then((res) => res.data);

export const fetcherPost = <T = unknown, R = unknown>(
  url: string,
  { arg }: { arg: T },
) => api.post<R>(url, arg).then((res) => res.data);

export const fetcherPut = <T = unknown, R = unknown>(
  url: string,
  { arg }: { arg: T },
) => api.put<R>(url, arg).then((res) => res.data);

export const fetcherPatch = <T = unknown, R = unknown>(
  url: string,
  { arg }: { arg: T },
) => api.patch<R>(url, arg).then((res) => res.data);

export const fetcherDelete = <R = unknown>(url: string) =>
  api.delete<R>(url).then((res) => res.data);

export const useApi = <T = unknown>(
  url: string,
  params?: unknown,
  fallback?: T,
) => {
  const { data, mutate, isLoading, error } = useSWR<T>(
    { url, params },
    fetcher,
    {
      revalidateOnFocus: false,
      fallbackData: fallback,
    },
  );

  return { data, mutate, isLoading, error };
};
