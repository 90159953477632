import {
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { WithRole } from "~lib/future/roles";
import { logout } from "~lib/logout";

export default function ProfileDropdown() {
  const addSiteUrl: string = `/sites/new`;
  const adminPage: string = `/admin`;

  return (
    <MenuList zIndex="10" fontSize="13px">
      <MenuOptionGroup>
        <WithRole validRoles={["admin", "org-admin"]}>
          <MenuItem color="text" as={NextLink} href={"/sites"}>
            All sites
          </MenuItem>
          <MenuItem color="text" as="a" href={addSiteUrl}>
            Add a site
          </MenuItem>
          <MenuItem color="text" as={NextLink} href={adminPage}>
            Admin panel
          </MenuItem>
          <MenuDivider width="70%" />
        </WithRole>
      </MenuOptionGroup>

      <WithRole validRoles={["admin", "org-admin"]}>
        <MenuItem color="text" as={NextLink} href={"/settings"}>
          Settings
        </MenuItem>
      </WithRole>

      <MenuItem
        color="text"
        onClick={async (event) => {
          event.stopPropagation();
          await logout();

          window.location.href = "/";
        }}
      >
        Log out
      </MenuItem>
    </MenuList>
  );
}
